import "./App.css";
import { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, TextField } from "@material-ui/core";
import { Container, Fab, Paper, Backdrop, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const fabStyle = {
  position: "absolute",
  bottom: 30,
  right: 25,
};

const API_URL = "https://social-media-worker.jravi.workers.dev/"

function App() {
  const [posts, setPosts] = useState([]);
  const [username, setUsername] = useState("");
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    const resp = await fetch(API_URL + "posts");
    const postsResp = await resp.json();
    setPosts(postsResp["posts"]);
    setIsLoading(false);
  };

  const changeUsername = (e) => {
    setUsername(e.target.value);
  };

  const changeContent = (e) => {
    setContent(e.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      username,
      content,
    });

    let requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      // redirect: "follow",
    };

    fetch(API_URL + "posts", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        getPosts();
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="App">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Postify
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxwidth="lg">
        <Paper className="post" id="post-form">
          <TextField
            id="username-field"
            label="Username"
            variant="outlined"
            value={username}
            onChange={changeUsername}
          />{" "}
          <br />
          <TextField
            multiline
            id="content-field"
            label="Tell us what's going on!"
            variant="outlined"
            rows="6"
            value={content}
            onChange={changeContent}
          />
          <Fab sx={fabStyle} color="primary" aria-label="add" onClick={handleSubmit}>
            <AddIcon />
          </Fab>
        </Paper>
        {posts.map((post) => (
          <Paper className="post" key={post.id}>
            <div className="post-header">
              <h4 className="username">@{post.username}</h4>
              <p className="timestamp">{post.timestamp}</p>
            </div>
            <h2 className="title">{post.title}</h2>
            <p className="content">{post.content}</p>
          </Paper>
        ))}
      </Container>
    </div>
  );
}

export default App;
